import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import { GlobalState } from '../../store';
import { TaskTrackingTable } from '../tasktrackingTable/taskTableContainer';

// The content in the main content area of the App layout
export default function HomepageContent(props: GlobalState) {
  return (
    <Box className="taskTrackingTable-box">
      <Grid gridDefinition={[{ colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { xxs: 1 } }]}>
        <div>
          <Header variant="h1" headingTagOverride="h2">
            Task Tracker
          </Header>

          <TaskTrackingTable />
        </div>
      </Grid>
    </Box>
  );
}
