import * as actions from './actions';
import { MetaAudit, SubmissionRequest } from '../../metaaudits-api/generated-src';

export interface MetaAuditsTableState {
  allItems: MetaAudit[];
  refreshTableFlag: boolean;
  responseVisible: boolean;
  responseHeader: string;
  responseMessage: string;
  approveVisible: boolean;
  rejectVisible: boolean;
  waitingResponse: boolean;
  metaAudit: MetaAudit;
  selectedTaggingTool: string;
  selectedTaskId: string;
  selectedKey: string;
}

export const initialState: MetaAuditsTableState = {
  allItems: [],
  refreshTableFlag: false,
  responseVisible: false,
  responseHeader: 'default header',
  responseMessage: 'default message',
  approveVisible: false,
  rejectVisible: false,
  waitingResponse: false,
  metaAudit: null!,
  selectedTaggingTool: '',
  selectedTaskId: '',
  selectedKey: '',
};

const actionMapper = {
  [actions.TOGGLE_REFRESH_TABLE_FLAG]: (state) => {
    return {
      ...state,
      waitingResponse: true,
      refreshTableFlag: !state.refreshTableFlag,
    };
  },

  //Setters
  [actions.SET_ALL_ITEMS]: (state, { value }) => {
    return {
      ...state,
      allItems: value,
    };
  },
  [actions.SET_RESPONSE_VISIBLE]: (state, { value }) => {
    return {
      ...state,
      responseVisible: value,
    };
  },
  [actions.SET_RESPONSE_HEADER]: (state, { value }) => {
    return {
      ...state,
      responseHeader: value,
    };
  },
  [actions.SET_RESPONSE_MESSAGE]: (state, { value }) => {
    return {
      ...state,
      responseMessage: value,
    };
  },
  [actions.SET_WAITING_RESPONSE]: (state, { value }) => {
    return {
      ...state,
      waitingResponse: !!value,
    };
  },
  [actions.SET_META_AUDIT]: (state, { value }) => {
    var n = value.s3_location.lastIndexOf('/');
    var key = value.s3_location.substring(n + 1);
    return {
      ...state,
      metaAudit: value,
      selectedTaggingTool: value.tagging_tool,
      selectedTaskId: value.task_id,
      selectedKey: key,
    };
  },

  //POST Operations
  [actions.APPROVE_OPERATION]: (state) => {
    return {
      ...state,
      waitingResponse: true,
    };
  },
  [actions.REJECT_OPERATION]: (state) => {
    return {
      ...state,
      waitingResponse: true,
    };
  },
};

export default function metaAuditsTableReducer(state: MetaAuditsTableState = initialState, action) {
  if (typeof actionMapper[action.type] === 'function') {
    return actionMapper[action.type](state, action);
  }

  return state;
}

export const stateKey = 'metaAuditsTable';
