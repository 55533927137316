import { Redirect, Route, Switch } from 'react-router-dom';
import MetaAuditPage from './metaAuditPage'
import CreateTaskPage from './createTaskPage'
import TaskTrackingPage from './taskTrackingPage'
import NavigationHeader from './navigation/NavigationHeader';

export default function App() {
  return (
    <div>
      <NavigationHeader />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/MetaAudits" />} />
        <Route exact path="/MetaAudits" component={MetaAuditPage} />
        <Route exact path="/CreateTasks" component={CreateTaskPage} />
        <Route exact path="/TaskTracking" component={TaskTrackingPage} />
      </Switch>
    </div>
  );
}
