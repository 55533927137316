import Container from '@amzn/awsui-components-react/polaris/container';
import { Header } from '@amzn/awsui-components-react';
import { TaskTable } from './Table';

export function TaskTrackingTable() {
  return (
    <Container header={<Header variant="h2">Create Task</Header>}>
      <TaskTable />
    </Container>
  );
}
