import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { TopNavigation } from '@amzn/awsui-components-react/polaris';

// Based on https://code.amazon.com/packages/Capstone-project/blobs/mainline/--/react-app/src/components/TopNavigation.jsx

export default function NavigationHeader() {
  return (
    <TopNavigation
    identity={{
      href: "#",
      title: "CTMS"
    }}
    utilities={[
      {
        type: "button",
        text: "Meta-Audits",
        href: "#/MetaAudits",
        external: false,
      },
      {
        type: "button",
        text: "Create-Tasks",
        href: "#/CreateTasks",
        external: false,
      },
      {
        type: "button",
        text: "Task-Tracking",
        href: "#/TaskTracking",
        external: false,
      },
    ]}
    i18nStrings={{
      overflowMenuTitleText: "Title",
      searchIconAriaLabel: "Search",
      searchDismissIconAriaLabel: "Close search",
      overflowMenuTriggerText: "More"
    }}
    
  />
  );
}