import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { GlobalState } from '../../store';
import CreateTaskPageContent from './CreateTaskPageContent';
import './styles.scss';

export default function CreateTaskPage(props: GlobalState) {
  return (
    <AppLayout
      content={<CreateTaskPageContent {...props} />}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}
