import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import TaskTrackingPageContent from './TaskTrackingPageContent';
import { GlobalState } from '../../store';
import './styles.scss';

export default function TaskTrackingPage(props: GlobalState) {
  return (
    <AppLayout
      content={<TaskTrackingPageContent {...props} />}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}


