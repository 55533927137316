import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ReactNode } from 'react';
import { TaskInfo } from './reducer';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TaskInfo>[] = [
  {
    id: 'TaskName',
    header: 'Task Name',
    cell: (item) => item.TaskName,
    minWidth: '20px',
  },
  {
    id: 'TaskId',
    header: 'Task Id',
    cell: (item) => item.TaskId,
    minWidth: '20px',
  },
  {
    id: 'TaskType',
    header: 'Task Type',
    cell: (item) => item.TaskType,
    minWidth: '20px',
  },
  {
    id: 'TaskStatus',
    header: 'Task Status',
    cell: (item) => item.TaskStatus,
    minWidth: '20px',
  },
  {
    id: 'RequesterId',
    header: 'Requester Id',
    cell: (item) => item.RequesterId,
    minWidth: '20px',
  }
];

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};