import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';

import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { MetaAudit } from '../../metaaudits-api/generated-src';
import { ReactNode } from 'react';
import { Button, Link, SpaceBetween } from '@amzn/awsui-components-react';
import Modal from '@amzn/awsui-components-react/polaris/modal';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<MetaAudit>[] = [
  {
    id: 'task_id',
    header: 'Task Id',
    cell: (item) => item.task_id,
    minWidth: '20px',
  },
  {
    id: 'tagging_tool',
    cell: (item) => item.tagging_tool,
    header: 'Source',
    minWidth: '20px',
  },
  {
    id: 'marketplace',
    cell: (item) => item.marketplace,
    header: 'Marketplace',
    minWidth: '20px',
  },
  {
    id: 'week',
    cell: (item) => item.week,
    header: 'Year/Week',
    minWidth: '20px',
  },
  {
    id: 'task_name',
    cell: (item) => item.task_name,
    header: 'Task Name',
    minWidth: '20px',
  },
  {
    id: 'task_type',
    cell: (item) => item.task_type,
    header: 'Task Type',
    minWidth: '20px',
  },
  {
    id: 'status',
    cell: (item) => item.status,
    header: 'Status',
    minWidth: '20px',
  },
  {
    id: 'timestamp',
    cell: (item) => new Date(parseInt(item.timestamp) * 1000).toLocaleDateString("en-US"),
    header: 'Last Updated',
    minWidth: '20px',
  },
  {
    id: 's3_download_link',
    cell: (item) => (item.s3_download_link)?(<Link href={item.s3_download_link}>Download File</Link>):(""),
    header: 'S3 Meta Audit Location',
    minWidth: '160px',
  },
  {
    id: 'metadata',
    cell: (item) => item.metadata,
    header: 'Task Metadata',
    minWidth: '160px',
  },
];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export function PopupModal({ visible, setPopupVisible, header, description, popupText, popupAction }) {
  return (
    <Modal
      onDismiss={() => setPopupVisible(false)}
      visible={visible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            {popupText != null && popupAction != null && (
              <Button
                variant="primary"
                onClick={() => {
                  popupAction();
                  setPopupVisible(false);
                }}
              >
                {popupText}
              </Button>
            )}
          </SpaceBetween>
        </Box>
      }
      header={header}
    >
      {description}
    </Modal>
  );
}

export function PopupResponse({ visible, setPopupVisible, header, description }) {
  return (
    <Modal
      onDismiss={() => setPopupVisible(false)}
      visible={visible}
      closeAriaLabel="Close modal"
      size="medium"
      header={header}
    >
      {description}
    </Modal>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' },
];
