import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { GlobalState } from '../../store';
import { CreationForm } from '../createTaskForm/CreationFormContainer';

// The content in the main content area of the App layout
export default function HomepageContent(props: GlobalState) {
  return (
    <Box className="taskCreationForm-box">
      <Grid gridDefinition={[{ colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { xxs: 1 } }]}>
        <div>
          <Header variant="h1" headingTagOverride="h2">
            Create a Task
          </Header>

          <div className="alert-padding">
            <Alert header="Useful information for filling out the form">
              Please review the information provided in{' '}
              {
                <a
                  href="https://w.amazon.com/bin/view/TuringTeam/TTMSMetaAudits/" // change the link to the correct wiki page
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  this guide
                </a>
              }{' '}
              for explanations for different task types.
            </Alert>
          </div>

          <CreationForm />
        </div>
      </Grid>
    </Box>
  );
}
