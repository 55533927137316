import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { GlobalState } from '../../store';
import MetaAuditPageContent from './MetaAuditPageContent';
import './styles.scss';

export default function MetaAuditPage(props: GlobalState) {
  return (
    <AppLayout
      content={<MetaAuditPageContent {...props}/>}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}
