import Container from '@amzn/awsui-components-react/polaris/container';
import { Header } from '@amzn/awsui-components-react';
import { CreateTaskForm } from './CreationFormContent';

export function CreationForm() {
  return (
    <Container header={<Header variant="h2">Create Task</Header>}>
      <CreateTaskForm />
    </Container>
  );
}
