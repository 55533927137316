import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import { MetaAuditsTable } from '../metaAuditsTable/Table';
import { GlobalState } from '../../store';

// The content in the main content area of the App layout
export default function HomepageContent(props: GlobalState) {
  return (
    <Box className="metaaudit-box">
      <Grid gridDefinition={[{ colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { xxs: 1 } }]}>
        <div>
            <Header variant="h1" headingTagOverride="h2">
              Vendor Meta Audits
            </Header>
            <MetaAuditsTable {...props} />
          </div>
      </Grid>
    </Box>
  );
}
