import axios from 'axios';
import { put, takeLatest, all, select } from 'redux-saga/effects';
import { getAppSetting, initializeAppSettings } from '../../config/AppSettings';
import * as actions from './actions';
import { TaskInfo } from './reducer';
import * as selectors from './selectors';

function* getApiFromConfig() {
  const appRaw = yield axios('/settings.json');
  const apiUrlBase = appRaw.data.javaBackendApiUrl;
  return apiUrlBase;
}

async function getDropdownResponse(apiUrlBase: string) {
  const response = await fetch(`${apiUrlBase}/getDropdown`);
  const data = await response.json();
  return data;
}

function* getDropdownVals() {
  const apiUrlBase = yield getApiFromConfig();
  try {
    const data = yield getDropdownResponse(apiUrlBase);
    yield put(actions.receiveDropdownVals(data));
  } catch (e) {
    console.log(e);
  }
}

async function getTaskSubmissionRes(apiUrlBase: string, taskInfo: TaskInfo) {
  const response = await fetch(`${apiUrlBase}/submitTask`, {
    method: 'POST',
    body: JSON.stringify({
      clientId: taskInfo.clientId,
      taskName: taskInfo.taskName,
      marketplace: taskInfo.marketplace,
      s3Path: taskInfo.s3Location,
      taskType: taskInfo.taskType,
      taskDesc: taskInfo.taskDesc,
      skipDedupeCheck: String(taskInfo.skipCheck),
      tagGranularity: taskInfo.tagGranularity,
      auditorQualification: taskInfo.auditorQualification,
    }),
  });
  const data = await response.json();
  return data;
}

function* submitTaskToApi() {
  const taskInfo = yield select(selectors.getTaskInfo);

  const apiUrlBase = yield getApiFromConfig();

  try {
    const res = yield getTaskSubmissionRes(apiUrlBase, taskInfo);
    if (res.SubmissionResponse.includes('errorMessage')) {
      yield put(actions.receiveSubmissionResFail());
    } else {
      yield put(actions.receiveSubmissionResOk(res));
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* sagas() {
  yield all([
    takeLatest(actions.REQUEST_DROPDOWN_VALS, getDropdownVals),
    takeLatest(actions.SUBMIT_OPERATION, submitTaskToApi),
  ]);
}
