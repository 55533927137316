import { put, takeLatest, all, select } from 'redux-saga/effects';
import * as actions from './actions';

async function getTasksResponse() {
  const response = await fetch('https://fyd78umvjd.execute-api.us-west-2.amazonaws.com/luckyjz/fetchTasks');
  const data = await response.json();
  return data;
}

function* getAllTasks() {
  try {
    const data = yield getTasksResponse();
    yield put(actions.receiveAllTasks(data));
  } catch (e) {
    console.log(e);
  }
}

export default function* sagas() {
  yield all([takeLatest(actions.REQUEST_ALL_TASKS, getAllTasks)]);
}
