import Form from '@amzn/awsui-components-react/polaris/form';
import {
  Autosuggest,
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  Modal,
  Textarea,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { CreateTaskFormState, TaskInfo } from './reducer';
import { requestDropdownVals, setModalVisible, setTaskInfo, submitOperation } from './actions';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface StateProps {
  createTaskFormState: CreateTaskFormState;
}

interface DispatchProps {
  requestDropdownVals: () => void;
  setTaskInfo: (response: any) => void;
  submitOperation: () => void;
  setModalVisible: (visible: boolean) => void;
}

export function CreationFormContent(props: StateProps & DispatchProps & RouteComponentProps) {
  const [taskName, setTaskName] = useState('');
  const [marketplace, setMarketplace] = useState('');
  const [s3Location, setS3Location] = useState('');
  const [taskType, setTaskType] = useState('');
  const [taskDesc, setTaskDesc] = useState('');
  const [clientId, setClientId] = useState(props.createTaskFormState.clientId);
  const [skipCheck, setSkipCheck] = useState(false);
  const [tagGranularity, setGranularity] = useState('UNSPECIFIED');
  const [auditorQualification, setQualification] = useState('UNSPECIFIED');

  useEffect(() => {
    props.requestDropdownVals();
    props.setModalVisible(false);
  }, []);

  // add task
  const createTask = () => {
    const taskInfo: TaskInfo = {
      taskName: taskName,
      marketplace: marketplace,
      s3Location: s3Location,
      taskType: taskType,
      taskDesc: taskDesc,
      clientId: clientId,
      skipCheck: skipCheck,
      tagGranularity: tagGranularity,
      auditorQualification: auditorQualification.split(' ')[0],
    };

    props.setTaskInfo(taskInfo);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    createTask();
    props.submitOperation();

    // clear form fields
    setTaskName('');
    setMarketplace('');
    setS3Location('');
    setTaskType('');
    setTaskDesc('');
    setClientId(props.createTaskFormState.clientId);
    setSkipCheck(false);
    setGranularity('UNSPECIFIED');
    setQualification('UNSPECIFIED');
  };

  const validateForm = () => {
    // check if all required fields are filled
    if (taskName === '' || marketplace === '' || s3Location === '' || taskType === '' || taskDesc === '') {
      return true;
    }

    return taskName.length > 140;
  };

  const validateField = () => {
    return taskName.length > 140;
  }

  const closeModal = () => {
    props.setModalVisible(false);
  }

  return (
    <form onSubmit={onSubmit}>
      <Form
        actions={
          <Button loading={props.createTaskFormState.submitting} disabled={validateForm()} variant="primary">
            Submit
          </Button>
        }
      >
        {/* create the input fields */}
        <div>
          <label>Client ID</label>
          <Input onChange={({ detail }) => setClientId(detail.value)} value={clientId} />
        </div>

        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <div>
            <label>Audit Task Name</label>
            <Input onChange={({ detail }) => setTaskName(detail.value)} value={taskName} invalid={validateField()}/>
          </div>

          <div>
            <label>Marketplace</label>
            <Autosuggest
              onChange={({ detail }) => setMarketplace(detail.value)}
              value={marketplace}
              options={props.createTaskFormState.allDropdownVals.Marketplace}
              enteredTextLabel={(value) => `Use: "${value}"`}
              placeholder="Enter value"
              empty="No matches found"
              statusType={props.createTaskFormState.dropdownLoading ? 'loading' : 'finished'}
            />
          </div>
        </Grid>

        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <div>
            <label>Input S3 Location</label>
            <Input onChange={({ detail }) => setS3Location(detail.value)} value={s3Location} />
          </div>

          <div>
            <label>Task Type</label>
            <Autosuggest
              onChange={({ detail }) => setTaskType(detail.value)}
              value={taskType}
              options={props.createTaskFormState.allDropdownVals.TaskType}
              enteredTextLabel={(value) => `Use: "${value}"`}
              placeholder="Enter value"
              empty="No matches found"
              statusType={props.createTaskFormState.dropdownLoading ? 'loading' : 'finished'}
            />
          </div>
        </Grid>

        <div>
          <label>Audit Task Description</label>
          <Textarea
            onChange={({ detail }) => setTaskDesc(detail.value)}
            value={taskDesc}
            placeholder="Enter audit task description here"
          />
        </div>

        <div>
          <Checkbox
            className="skipCheckBox"
            onChange={({ detail }) => setSkipCheck(detail.checked)}
            checked={skipCheck}
          >
            Check to skip DedupeCheck (Optional)
          </Checkbox>
        </div>

        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <div>
            <label>Tag Granularity (Optional)</label>
            <Autosuggest
              onChange={({ detail }) => setGranularity(detail.value)}
              value={tagGranularity}
              options={props.createTaskFormState.allDropdownVals.TagGranularity}
              enteredTextLabel={(value) => `Use: "${value}"`}
              placeholder="Enter value"
              empty="No matches found"
              statusType={props.createTaskFormState.dropdownLoading ? 'loading' : 'finished'}
            />
          </div>

          <div>
            <label>Auditor Qualification (Optional)</label>
            <Autosuggest
              onChange={({ detail }) => setQualification(detail.value)}
              value={auditorQualification}
              options={props.createTaskFormState.allDropdownVals.AuditorQualification}
              enteredTextLabel={(value) => `Use: "${value}"`}
              placeholder="Enter value"
              empty="No matches found"
              statusType={props.createTaskFormState.dropdownLoading ? 'loading' : 'finished'}
            />
          </div>
        </Grid>
      </Form>

      <Modal
        visible={props.createTaskFormState.submissionModalVisible}
        onDismiss={closeModal}
        closeAriaLabel="Close modal"
        header="Task Creation"
        footer={
          <Box float="right">
            <Button href="#/TaskTracking" variant="primary">
              Go To Task Tracking Page
            </Button>
          </Box>
        }
      >
        <div>Task creation {props.createTaskFormState.submissionSuccess ? 'succeed.' : 'fail.'}</div>
        <div>
          {props.createTaskFormState.submissionSuccess
            ? `Your task id is: ${props.createTaskFormState.taskIdReturned}`
            : ``}
        </div>
      </Modal>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    createTaskFormState: state.createTaskForm,
  };
};

const mapDispatchToProps = {
  requestDropdownVals,
  setTaskInfo,
  submitOperation,
  setModalVisible,
};

export const CreateTaskForm = connect(mapStateToProps, mapDispatchToProps)(withRouter(CreationFormContent));
