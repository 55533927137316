import * as actions from './actions';

export interface TaskInfo {
  RequesterId: string;
  TaskName: string;
  TaskId: string;
  TaskStatus: string;
  TaskType: string;
}

export interface TaskTrackingState {
  loading: boolean;
  tasks: TaskInfo[];
}

export const initialState: TaskTrackingState = {
  loading: false,
  tasks: [],
};

const actionMapper = {
  [actions.REQUEST_ALL_TASKS]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },

  //Setters
  [actions.RECEIVE_ALL_TASKS]: (state, { value }) => {
    return {
      ...state,
      tasks: value.TaskInfoList,
      loading: false,
    };
  },
};

export default function taskTrackingTableReducer(state: TaskTrackingState = initialState, action) {
  if (typeof actionMapper[action.type] === 'function') {
    return actionMapper[action.type](state, action);
  }

  return state;
}

export const stateKey = 'taskTrackingTable';
