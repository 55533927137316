import * as actions from './actions';
import { attachStringToList } from './helperFunctions';

export interface TaskInfo {
  taskName: string;
  marketplace: string;
  s3Location: string;
  taskType: string;
  taskDesc: string;
  clientId: string;
  skipCheck: boolean;
  tagGranularity: string;
  auditorQualification: string;
}

export interface CreateTaskFormState {
  allDropdownVals: any;
  dropdownLoading: boolean;
  submitting: boolean;
  submissionModalVisible: boolean;
  submissionSuccess: boolean;
  taskInfo: TaskInfo;
  clientId: string;
  taskSubmissionRes: any;
  taskIdReturned: string;
}

export const initialState: CreateTaskFormState = {
  allDropdownVals: {},
  dropdownLoading: false,
  submitting: false,
  submissionModalVisible: false,
  submissionSuccess: false,
  taskInfo: {} as TaskInfo,
  clientId: '',
  taskSubmissionRes: {},
  taskIdReturned: '',
};

const actionMapper = {
  [actions.REQUEST_DROPDOWN_VALS]: (state) => {
    return {
      ...state,
      dropdownLoading: true,
    };
  },
  [actions.SUBMIT_OPERATION]: (state) => {
    return {
      ...state,
      submitting: true,
    };
  },
  [actions.RECEIVE_SUBMISSION_RES_FAIL]: (state) => {
    return {
      ...state,
      submitting: false,
      submissionModalVisible: true,
      submissionSuccess: false,
    };
  },

  //Setters
  [actions.RECEIVE_DROPDOWN_VALS]: (state, { value }) => {
    value.AuditorQualification = attachStringToList(value.AuditorQualification);
    return {
      ...state,
      allDropdownVals: value,
      dropdownLoading: false,
    };
  },
  [actions.SET_TASK_INFO]: (state, { value }) => {
    return {
      ...state,
      taskInfo: value,
    };
  },
  [actions.RECEIVE_SUBMISSION_RES_OK]: (state, { value }) => {
    const taskId = JSON.parse(JSON.parse(value.SubmissionResponse).body).taskId;
    return {
      ...state,
      taskSubmissionRes: value,
      submitting: false,
      submissionModalVisible: true,
      submissionSuccess: true,
      taskIdReturned: taskId,
    };
  },
  [actions.GET_CLIENT_ID]: (state, { value }) => {
    return {
      ...state,
      clientId: value,
    };
  },
  [actions.SET_MODAL_VISIBLE]: (state, { value }) => {
    return {
      ...state,
      submissionModalVisible: value,
    };
  },
};

export default function createTaskFormReducer(state: CreateTaskFormState = initialState, action) {
  if (typeof actionMapper[action.type] === 'function') {
    return actionMapper[action.type](state, action);
  }

  return state;
}

export const stateKey = 'createTaskForm';
