const modulePrefix = 'createTaskForm/';

export const REQUEST_DROPDOWN_VALS = `${modulePrefix}REQUEST_DROPDOWN_VALS`;
export const RECEIVE_DROPDOWN_VALS = `${modulePrefix}RECEIVE_DROPDOWN_VALS`;
export const SET_TASK_INFO = `${modulePrefix}SET_TASK_INFO`;
export const SUBMIT_OPERATION = `${modulePrefix}SUBMIT_OPERATION`;
export const RECEIVE_SUBMISSION_RES_OK = `${modulePrefix}RECEIVE_SUBMISSION_RES_OK`;
export const RECEIVE_SUBMISSION_RES_FAIL = `${modulePrefix}RECEIVE_SUBMISSION_RES_FAIL`;
export const GET_CLIENT_ID = `${modulePrefix}GET_CLIENT_ID`;
export const SET_MODAL_VISIBLE = `${modulePrefix}SET_MODAL_VISIBLE`;

export const requestDropdownVals = () => ({
  type: REQUEST_DROPDOWN_VALS,
});

export const receiveDropdownVals = (value) => ({
  type: RECEIVE_DROPDOWN_VALS,
  value,
});

export const setTaskInfo = (value) => ({
  type: SET_TASK_INFO,
  value,
});

export const submitOperation = () => ({
  type: SUBMIT_OPERATION,
});

export const receiveSubmissionResOk = (value) => ({
  type: RECEIVE_SUBMISSION_RES_OK,
  value,
});

export const receiveSubmissionResFail = () => ({
  type: RECEIVE_SUBMISSION_RES_FAIL,
});

export const getClientId = (value) => ({
  type: GET_CLIENT_ID,
  value,
});

export const setModalVisible = (value) => ({
  type: SET_MODAL_VISIBLE,
  value,
});
