import { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import {
  COLUMN_DEFINITIONS,
  getMatchesCountText,
  EmptyState,
  paginationLabels,
  PopupModal,
  PopupResponse,
} from './table-config';
import { MetaAudit } from '../../metaaudits-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GlobalState } from '../../store';
import { MetaAuditsTableState } from './reducer';
import _default from '@restart/ui/esm/Nav';
import { approveOperation, rejectOperation, setMetaAudit, setResponseVisible, toggleRefreshTableFlag } from './actions';
import { Link } from '@amzn/awsui-components-react';

interface StateProps {
  metaAuditsTable: MetaAuditsTableState;
}

interface DispatchProps {
  toggleRefreshTableFlag: () => void;
  rejectOperation: () => void;
  approveOperation: () => void;
  setResponseVisible: (visible: boolean) => void;
  setMetaAudit: (metaAudit: MetaAudit) => void;
}

export function MetaAuditsTableComponent(props: StateProps & DispatchProps & RouteComponentProps) {
  const [approveVisible, setApproveVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);

  const refreshButton = <Button variant="icon" iconName="refresh" onClick={() => props.toggleRefreshTableFlag()} />;

  //Refresh table items on initial load
  useEffect(() => {
    props.toggleRefreshTableFlag();
  }, []);

  //Sort and filter displayed table items
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    props.metaAuditsTable.allItems || [],
    {
      filtering: {
        empty: <EmptyState title="No meta audits" subtitle="No open meta audits to display." action={refreshButton} />,
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: {
        pageSize: 10,
      },
      sorting: {},
      selection: {},
    }
  );

  function openApproveWindow(metaAudit: MetaAudit) {
    setApproveVisible(true);
    props.setMetaAudit(metaAudit);
  }

  function openRejectWindow(metaAudit: MetaAudit) {
    setRejectVisible(true);
    props.setMetaAudit(metaAudit);
  }

  function generatePopupWindows() {
    return (
      <div>
        <PopupResponse
          visible={props.metaAuditsTable.responseVisible}
          setPopupVisible={props.setResponseVisible}
          header={props.metaAuditsTable.responseHeader}
          description={props.metaAuditsTable.responseMessage}
        ></PopupResponse>
        <PopupModal
          visible={approveVisible}
          setPopupVisible={setApproveVisible}
          header={'Confirm Approval'}
          description={'Are you sure you want to approve task id: ' + props.metaAuditsTable.selectedTaskId}
          popupText={'Approve'}
          popupAction={() => props.approveOperation()}
        ></PopupModal>
        <PopupModal
          visible={rejectVisible}
          setPopupVisible={setRejectVisible}
          header={'Confirm Reject'}
          description={'Are you sure you want to reject task id: ' + props.metaAuditsTable.selectedTaskId}
          popupText={'Reject'}
          popupAction={() => props.rejectOperation()}
        ></PopupModal>
      </div>
    );
  }

  const { selectedItems } = collectionProps;
  return (
    <div>
      {generatePopupWindows()}
      <div className="alert-padding">
        <Alert header="Standard Operating Procedure">
          Please review the information provided in{' '}
          {
            <a href="https://w.amazon.com/bin/view/TuringTeam/TTMSMetaAudits/" target="_blank" rel="noopener noreferrer">
              this guide
            </a>
          }{' '}
          before approving/rejecting any meta audits.
        </Alert>
      </div>
      <Table
        {...collectionProps}
        loading={!props.metaAuditsTable.allItems}
        loadingText="Loading instances"
        selectionType="single"
        header={
          <Header
            counter={
              props.metaAuditsTable.allItems &&
              (selectedItems!.length
                ? `(${selectedItems!.length}/${props.metaAuditsTable.allItems.length})`
                : `(${props.metaAuditsTable.allItems.length})`)
            }
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <Button variant="normal" iconName="refresh" onClick={() => props.toggleRefreshTableFlag()}></Button>

                <Button
                  variant="primary"
                  iconName="status-positive"
                  disabled={selectedItems!.length == 0 || props.metaAuditsTable.waitingResponse}
                  onClick={() => openApproveWindow(selectedItems![0])}
                >
                  Approve
                </Button>

                <Button
                  variant="primary"
                  iconName="refresh"
                  disabled={selectedItems!.length == 0 || props.metaAuditsTable.waitingResponse}
                  onClick={() => openRejectWindow(selectedItems![0])}
                >
                  Reject
                </Button>
              </SpaceBetween>
            }
          >
            Meta Audits
          </Header>
        }
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount!)}
            filteringAriaLabel="Filter records"
          />
        }
      />
    </div>
  );
}

const mapStateToProps = (state: GlobalState, ownProps: StateProps) => {
  return {
    metaAuditsTable: state.metaAuditsTable,
  };
};

const mapDispatchToProps = {
  toggleRefreshTableFlag,
  approveOperation,
  rejectOperation,
  setResponseVisible,
  setMetaAudit,
};

export const MetaAuditsTable = connect(mapStateToProps, mapDispatchToProps)(withRouter(MetaAuditsTableComponent));
