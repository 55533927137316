// function to attach a string to the end of each value in the input list, return the modified list
export const attachStringToList = (dropDownList) => {
  return dropDownList.map((entry) => {
    if (entry.value === 'EXTERNAL') {
      return { value: entry.value + ' (vendors)' };
    } else if (entry.value === 'INTERNAL') {
      return { value: entry.value + ' (annolog)' };
    } else {
      return { value: entry.value };
    }
  });
};
