const modulePrefix = 'metaAuditsTable/';

export const TOGGLE_REFRESH_TABLE_FLAG = `${modulePrefix}TOGGLE_REFRESH_TABLE_FLAG`;
export const SET_ALL_ITEMS = `${modulePrefix}SET_ALL_ITEMS`;
export const SET_RESPONSE_VISIBLE = `${modulePrefix}SET_RESPONSE_VISIBLE`;
export const SET_RESPONSE_HEADER = `${modulePrefix}SET_RESPONSE_HEADER`;
export const SET_RESPONSE_MESSAGE = `${modulePrefix}SET_RESPONSE_MESSAGE`;
export const SET_META_AUDIT = `${modulePrefix}SET_META_AUDIT`;
export const SET_WAITING_RESPONSE = `${modulePrefix}SET_WAITING_RESPONSE`;
export const REJECT_OPERATION = `${modulePrefix}REJECT_OPERATION`;
export const APPROVE_OPERATION = `${modulePrefix}APPROVE_OPERATION`;

export const toggleRefreshTableFlag = () => ({
  type: TOGGLE_REFRESH_TABLE_FLAG,
});

export const setAllItems = (value) => ({
  type: SET_ALL_ITEMS,
  value,
});

export const setResponseVisible = (value) => ({
  type: SET_RESPONSE_VISIBLE,
  value,
});

export const setResponseHeader = (value) => ({
  type: SET_RESPONSE_HEADER,
  value,
});

export const setResponseMessage = (value) => ({
  type: SET_RESPONSE_MESSAGE,
  value,
});

export const setWaitingResponse = (value) => ({
  type: SET_WAITING_RESPONSE,
  value,
});

export const setMetaAudit = (value) => ({
  type: SET_META_AUDIT,
  value,
});

export const rejectOperation = () => ({
  type: REJECT_OPERATION,
});

export const approveOperation = () => ({
  type: APPROVE_OPERATION,
});
