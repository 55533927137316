const modulePrefix = 'taskTrackingTable/';

export const REQUEST_ALL_TASKS = `${modulePrefix}REQUEST_ALL_TASKS`;
export const RECEIVE_ALL_TASKS = `${modulePrefix}RECEIVE_ALL_TASKS`;

export const requestAllTasks = () => ({
  type: REQUEST_ALL_TASKS,
});

export const receiveAllTasks = (value) => ({
  type: RECEIVE_ALL_TASKS,
  value,
});
