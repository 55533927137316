import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { requestAllTasks } from './actions';
import { TaskTrackingState } from './reducer';
import { COLUMN_DEFINITIONS, EmptyState, paginationLabels } from './table-config';
import Button from '@amzn/awsui-components-react/polaris/button';

interface StateProps {
  taskTrackingState: TaskTrackingState;
}

interface DispatchProps {
  requestAllTasks: () => void;
}

export function TaskTableContent(props: StateProps & DispatchProps & RouteComponentProps) {
  const refreshButton = <Button variant="icon" iconName="refresh" onClick={() => props.requestAllTasks() } />;

  useEffect(() => {
    props.requestAllTasks();
  }, []);

  //Sort and filter displayed table items
  const { items, actions, paginationProps } = useCollection(
    props.taskTrackingState.tasks || [],
    {
      filtering: {
        empty: <EmptyState title="No tasks" subtitle="No tasks to display." action={refreshButton} />,
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: {
        pageSize: 10,
      },
      sorting: {},
      selection: {},
    }
  );

  return (
    <div>
      <Table
        columnDefinitions={COLUMN_DEFINITIONS}
        selectionType="single"
        loading={props.taskTrackingState.loading}
        loadingText="Loading tasks"
        filter={
          <TextFilter
            filteringPlaceholder="Find resources"
            filteringText=""
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        items={items}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    taskTrackingState: state.taskTrackingTable,
  };
};

const mapDispatchToProps = {
  requestAllTasks,
};

export const TaskTable = connect(mapStateToProps, mapDispatchToProps)(withRouter(TaskTableContent));
