import axios from 'axios';
import { put, select, takeLatest, all } from 'redux-saga/effects';
import { initializeAppSettings } from '../../config/AppSettings';
import { SubmissionRequest } from '../../metaaudits-api/generated-src';
import MetaAuditsApiFactory from '../../metaaudits-api/MetaAuditsApiFactory';
import * as actions from './actions';
import { OPERATIONS } from './constants';
import * as selectors from './selectors';

let MetaAuditsApi: any = undefined;

function* GetSubmissionRequestFromStore(operation: String) {
  const taggingTool = yield select(selectors.getSelectedTaggingTool);
  const taskId = yield select(selectors.getSelectedTaskId);
  const key = yield select(selectors.getSelectedKey);

  let submissionRequest = {
    task_id: taskId,
    tagging_tool: taggingTool,
    key: key,
    operation: operation,
  } as SubmissionRequest;

  return submissionRequest;
}

function* getApiFromConfig() {
  // Initialize API if not defined.
  if (MetaAuditsApi == undefined) {
    const appRaw = yield axios('/settings.json');
    const appSettings = appRaw.data;
    initializeAppSettings(appSettings);
    MetaAuditsApi = MetaAuditsApiFactory();
  }
}

function* approveMetaAudit() {
  yield getApiFromConfig();
  let response: string | null = null;
  let error: string | null = null;
  try {
    let submissionRequest = yield GetSubmissionRequestFromStore(OPERATIONS.APPROVE);
    const serverResponse = yield MetaAuditsApi.approveRevokeMetaAudit(submissionRequest);
    response = serverResponse.data.message;
  } catch (e) {
    console.error('Exception captured during the approve Meta Audit api call');
    console.error(e);
    error = 'Encountered an error trying to approve meta audit, if this error persists please contact Turing Team.';
  }
  yield handleResponse('Approve Request', response, error);
  yield put(actions.toggleRefreshTableFlag());
}

function* rejectMetaAudit() {
  yield getApiFromConfig();
  let response: string | null = null;
  let error: string | null = null;
  try {
    let submissionRequest = yield GetSubmissionRequestFromStore(OPERATIONS.REJECT);
    const serverResponse = yield MetaAuditsApi.approveRevokeMetaAudit(submissionRequest);
    response = serverResponse.data.message;
  } catch (e) {
    console.error('Exception captured during the reject Meta Audit api call');
    console.error(e);
    error = 'Encountered an error trying to reject meta audit, if this error persists please contact Turing Team.';
  }

  yield handleResponse('Reject Request', response, error);
  yield put(actions.toggleRefreshTableFlag());
}

function* getOpenMetaAudits() {
  yield getApiFromConfig();
  let error: string | null = null;
  try {
    let response = yield MetaAuditsApi.listMetaAudits();
    yield put(actions.setAllItems(response.data));
  } catch (e) {
    console.error('Exception captured during call to get open metaaudits.');
    console.error(e);
    error = 'Encountered an error trying to get open meta audits, if this error persists please contact Turing Team.';
  }

  yield handleResponse('Get Open Meta Audits', undefined, error);
}

function* handleResponse(responseHeaderPrefix, response, error) {
  //Display the Error/Response depending on if the error value is populated
  if (error != undefined) {
    yield all([
      put(actions.setResponseHeader(`${responseHeaderPrefix} Failed`)),
      put(actions.setResponseMessage(error)),
      put(actions.setResponseVisible(true)),
    ]);
  } else if (response != undefined) {
    yield all([
      put(actions.setResponseHeader(`${responseHeaderPrefix} Succeeded`)),
      put(actions.setResponseMessage(response)),
      put(actions.setResponseVisible(true)),
    ]);
  }
  // Update Waiting Response to false
  yield put(actions.setWaitingResponse(false));
}

export default function* sagas() {
  yield all([
    takeLatest(actions.TOGGLE_REFRESH_TABLE_FLAG, getOpenMetaAudits),
    takeLatest(actions.APPROVE_OPERATION, approveMetaAudit),
    takeLatest(actions.REJECT_OPERATION, rejectMetaAudit),
  ]);
}
